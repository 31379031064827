import * as $ from 'jquery';
import { throttle as _throttle } from 'lodash';

export class FooterSnippetComponent {
    private $element:JQuery;
    private $linkContainer:JQuery;

    constructor(element) {
        this.$element = $(element);
        this.$linkContainer = this.$element.find('.tpl-footer-linkContainer');

        if(this.$linkContainer && this.$linkContainer.length > 0) {
            this.$element.before(`<div class="tpl-footer-filler" style="height: ${this.$element.outerHeight()}px;">&nbsp;<div>`);

            $(window).on('resize', _throttle(() => {
                $('.tpl-footer-filler').height(this.$element.outerHeight());
            }, 250))
        }
    }
}
