import { HomeTemplateComponent } from '~/site/templates/home/home';
import { AboutTemplateComponent } from '~/site/templates/about/about';
import { ProjectTemplateComponent } from '~/site/templates/project/project';
import { ErrorTemplateComponent } from '~/site/templates/error/error';
import { JournalTemplateComponent } from '~/site/templates/journal/journal';
import * as $ from 'jquery';

export class TemplateBundler {
    private $tplHome:JQuery;
    private $tplAbout:JQuery;
    private $tplProject:JQuery;
    private $tplError:JQuery;
    private $tplJournal:JQuery;

    constructor() {
        this.$tplHome = $(`.tpl--home`);
        if(this.$tplHome && this.$tplHome.length > 0) {
            new HomeTemplateComponent(this.$tplHome[0]);
        }

        this.$tplAbout = $(`.tpl--about`);
        if(this.$tplAbout && this.$tplAbout.length > 0) {
            new AboutTemplateComponent(this.$tplAbout[0]);
        }

        this.$tplProject = $(`.tpl--project`);
        if(this.$tplProject && this.$tplProject.length > 0) {
            new ProjectTemplateComponent(this.$tplProject[0]);
        }

        this.$tplJournal = $(`.tpl--journal`);
        if(this.$tplJournal && this.$tplJournal.length > 0) {
            new JournalTemplateComponent(this.$tplJournal[0]);
        }

        this.$tplError = $(`.tpl--error`);
        if(this.$tplError && this.$tplError.length > 0) {
            new ErrorTemplateComponent(this.$tplError[0]);
        }
    }
};
