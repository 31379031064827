import * as $ from 'jquery';
import { throttle as _throttle } from 'lodash';

export class HeaderSnippetComponent {
    private $element:JQuery;

    constructor(element) {
        this.$element = $(element);
        this.$element.before(`<div class="tpl-header-filler" style="height: ${this.$element.outerHeight()}px;">&nbsp;<div>`);

        $(window).on('resize', _throttle(() => {
            $('.tpl-header-filler').height(this.$element.outerHeight());
        }, 250))
    }
}
