import { HeaderSnippetComponent } from '~/site/snippets/header/header';
import { FooterSnippetComponent } from '~/site/snippets/footer/footer';
import * as $ from 'jquery';

export class SnippetBundler {
    private $tplHeader:JQuery;
    private $tplFooter:JQuery;

    constructor() {
        this.$tplHeader = $('.tpl-header');
        if(this.$tplHeader && this.$tplHeader.length > 0) {
            this.$tplHeader.each((_index, element) => {
                new HeaderSnippetComponent(element);
            });
        }

        this.$tplFooter = $('.tpl-footer');
        if(this.$tplFooter && this.$tplFooter.length > 0) {
            this.$tplFooter.each((_index, element) => {
                new FooterSnippetComponent(element);
            });
        }
    }
};
